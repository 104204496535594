<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->
                    
                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->

                    <!--Indication start-->
                    <Indication v-else-if="key == 'detail_indication' && item.show"/>
                    <!--Indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!--Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!--Conclusion end-->

                    <!--background start-->
                    <div class="content-onglets" v-else-if="key == 'show_background' && item.show">
                        <p v-html="data.ncpe['background']"></p>
                    </div>
                    <!--background end-->

                    <!--comparative_effectiveness start-->
                    <div class="content-onglets" v-else-if="key == 'show_comparative_effectiveness' && item.show">
                        <p v-html="data.ncpe['comparative_effectiveness']"></p>
                    </div>
                    <!--comparative_effectiveness end-->

                    <!--safety start-->
                    <div class="content-onglets" v-else-if="key == 'show_safety' && item.show">
                        <p v-html="data.ncpe['safety']"></p>
                    </div>
                    <!--safety end-->

                    <!--cost_effectiveness start-->
                    <div class="content-onglets" v-else-if="key == 'show_cost_effectiveness' && item.show"> 
                        <p v-html="data.ncpe['cost_effectiveness']"></p>
                    </div>
                    <!--cost_effectiveness end-->


                    <!-- detail_clinical_trials -->

                    <!-- detail_EvaluationEconomic  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.ncpe.evaluation_economic" />

                    <!-- detail_EconomicEvaluation -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!--BudgetImpact start-->
                    <BudgetImpact :data="data.ncpe['budget_impacts']" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                    <!--BudgetImpact end-->

                    <!--budget_impact start-->
                    <div class="content-onglets" v-else-if="key == 'show_budget_impact' && item.show">
                        <p v-html="data.ncpe['budget_impact']"></p>
                    </div>
                    <!--budget_impact end-->

                    <!--patient_submissions start-->
                    <div class="content-onglets" v-else-if="key == 'show_patient_submissions' && item.show">
                        <p v-html="data.ncpe['patient_submissions']"></p>
                    </div>
                    <!--patient_submissions end-->

                    <!--conclusion start-->
                    <div class="content-onglets" v-else-if="key == 'show_conclusion_text' && item.show">
                        <p v-html="data.ncpe['conclusion']"></p>
                    </div>
                    <!--conclusion end-->

                    <!--EssaisClinique start-->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!--EssaisClinique end-->   
                    
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    
                    <!-- Section Detail End -->

                </div>
            </template>           
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import Conclusion from '../conclusion.vue'
import BudgetImpact from '../budget_impact.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import InformationGeneral from '../InformationGeneral.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'

export default{
    name: 'ncpe',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,
        Conclusion,
        BudgetImpact,
        LinkAgency,
        EssaisClinique,
        EvaluationEconomic,
        KeyDocument,
        Indication,
        InformationGeneral
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_information_general : { 'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_indication : { 'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},               
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },
                show_background : {'title' : 'background', 'show' : true, 'enable' : false, 'orange_text':false },                
                show_comparative_effectiveness : {'title' : 'comparative_effectiveness', 'show' : true, 'enable' : false, 'orange_text':false },                
                show_safety : {'title' : 'Safety', 'show' : true, 'enable' : false, 'orange_text':false },                
                show_cost_effectiveness : {'title' : 'cost_effectiveness', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },           
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_BudgetImpact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true },                
                show_budget_impact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':false },                
                show_patient_submissions : {'title' : 'patient_submissions', 'show' : true, 'enable' : false, 'orange_text':false },                
                show_conclusion_text : {'title' : 'conclusion_text', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },    
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },                
            },
            
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.ncpe){
                            
            if(this.data['ma_date'] || this.data['submission_date'])
            { 
                this.list_check_box['detail_information_general'].enable = true
            }
            if(this.data['indication_en'])
            { 
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.ncpe['background'])
            { 
                this.list_check_box['show_background'].enable = true 
            } 
            if(this.data.ncpe['comparative_effectiveness'])
            { 
                this.list_check_box['show_comparative_effectiveness'].enable = true 
            } 
            if(this.data.ncpe['safety'])
            { 
                this.list_check_box['show_safety'].enable = true 
            }
            if(this.data.ncpe['cost_effectiveness'])
            { 
                this.list_check_box['show_cost_effectiveness'].enable = true 
            } 

            // detail_clinical_trials
            
            if(this.data.ncpe['evaluation_economic'].length > 0)
            { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            } 
            if(this.data.ncpe['budget_impacts'].length > 0)
            { 
                this.list_check_box['detail_BudgetImpact'].enable = true
            }
            if(this.data.ncpe['budget_impact'])
            { 
                this.list_check_box['show_budget_impact'].enable = true
            }
            if(this.data.ncpe['patient_submissions'])
            { 
                this.list_check_box['show_patient_submissions'].enable = true
            }
            if(this.data.ncpe['conclusion'])
            { 
                this.list_check_box['show_conclusion_text'].enable = true
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }

        }
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },        			
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }     
    },
}

</script>

<style scoped>
</style>